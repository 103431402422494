// External Dependencies
import React, { Component } from 'react';

// Component Definition
class LiveQuestionsForm extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    name: '',
    question: '',
    questionTouched: false,
  }

  handleBlur = (e) => {
    this.setState({ [`${e.target.name}Touched`]: true });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      name,
      question,
      questionTouched,
    } = this.state;

    const netlifyFormPath = 'question_success';
    const netlifyFormName = 'Live Questions';

    const questionValid = question.trim().length > 0;

    const questionErrorMessage = !questionValid && 'Required';

    const questionError = questionTouched && !!questionErrorMessage;

    const formValid = questionValid;

    return (
      <div>
        <form
          action={`/${netlifyFormPath}`}
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          method="post"
          name={netlifyFormName}
        >
          <input type="hidden" name="form-name" value={netlifyFormName} />

          <p hidden>
            <label htmlFor="hidden">
              Don’t fill this out:{' '}
              <input
                name="bot-field"
                onChange={(e) => this.handleChange(e)}
              />
            </label>
          </p>

          <label htmlFor="name">
            Name (optional)
            <input
              id="name"
              name="name"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={name}
            />
          </label>

          <label htmlFor="question">
            Question
            <textarea
              className={questionError ? 'input-error' : ''}
              id="question"
              name="question"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              value={question}
            />
          </label>

          <p hidden>
            <label htmlFor="hidden">
              Session
              <input
                name="session"
                value={process.env.GATSBY_APP_Q_AND_A_SESSION}
              />
            </label>
          </p>

          <div className="button-wrapper">
            <button
              className="button special-2"
              disabled={!formValid}
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default LiveQuestionsForm;
