// External Dependencies
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import LiveQuestionsForm from '../components/LiveQuestionsForm';

// Component definition
const LiveQuestions = () => (
  <Layout>
    <Meta title="Live Questions" />
    <HeaderGeneric
      title="Q & A"
      subtitle="Submit your question below"
    />

    <div id="main" className="form">
      <section id="content" className="main">
        <LiveQuestionsForm />
      </section>
    </div>

  </Layout>
);

export default LiveQuestions;
